import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import store from './redux/store'
import App from './App';
import {BrowserRouter} from 'react-router-dom'
import axios from 'axios';
(async()=>{
  // 先用refresh获取token
  await axios({
    headers: {
        'Content-Type':'application/json'
      },
    method:'POST',
    url:'https://gugunote-api.zhengchengshi.top/auth/refresh-token',
    data:JSON.stringify({refreshToken:"DSdTHtWKJWYFcxmPELzg"})
  }).then((res)=>{
    // console.log(res.data)
    localStorage.setItem('token_id',res.data.token)
  })
  ReactDOM.render(
    <React.StrictMode>
      <Provider store = {store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})()



