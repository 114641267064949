// import axios from 'axios'
// const baseUrl = 'https://gugunote-api.zhengchengshi.top'

// const api = {
//     get(url,data){
//         const token = `${localStorage.getItem('token_id')}`
//         if(data){
//             return axios({
//                 method:'GET',
//                 url:baseUrl+url+'?'+data,
//                 headers:{Authorization:token}
//             })

//         }
//         else{
//             return axios({
//                 method:'GET',
//                 url:baseUrl+url,
//                 headers:{Authorization:token}
//             })
//         }
//     },
//     post(url,data){
//         const token = `${localStorage.getItem('token_id')}`
//         if(data){
//             return axios({
//                 method:'post',
//                 url:baseUrl+url+'?'+data,
//                 data:data,
//                 headers:{authorization:token}
//             })
//         }
//         else{
//             return axios({
//                 method:'post',
//                 url:baseUrl+url,
//                 headers:{Authorization:token}
//             })
//         }
        
//     },
//     put(url){
//         const token = `${localStorage.getItem('token_id')}`
//         return axios({
//             method:'PUT',
//             url:baseUrl+url,
//             headers:{Authorization:token}
//         })
//     }
// }
// export default api
import HTTP from "../utils/HTTPS";


export default {
    get(url, data) {
        let option = { url, data }
        return HTTP.request(option,'GET')
    },
    post: function (url, data, contentType) {
        let option = { url, data, contentType }
        return HTTP.request(option, 'POST')
    },
    put(url, data) {
        let option = { url, data }
        return HTTP.request(option, 'PUT')
    },
    delete(url, data , contentType) {
        let option = { url, data,contentType }
        return HTTP.request(option, 'DELETE')
    }
}